<template lang="">
<div class="overview-wrapper d-flex flex-column pt-5" v-resize="emitCondenseFooterActions">
    <v-expansion-panels v-model="panel" multiple>
      <hb-expansion-panel
        success
        :left-cols="12"
        disable-title-icon
        :top-margin="false"
      >
        <template v-slot:title> Total Balance: <span class="pl-2">{{NationalAccount.keystat.totalBalance  | formatMoney}}</span></template>
<template v-slot:actions>
          <v-row no-gutters class="pl-2">
            <v-col
              cols="8"
              class="pa-0 ma-0 d-flex justify-end hb-font-header-3-medium"
            >
            </v-col>
          </v-row>
        </template>
<template v-slot:content>
  <v-row class="pa-2" no-gutters v-if="contactLeases && $vuetify.breakpoint.width > 600">
  <v-col>
    <v-row no-gutters class="pa-1">
      <v-col class="font-weight-medium">Total SQM</v-col>
      <v-col v-if="NationalAccount.keystat.sqm">{{ NationalAccount.keystat.sqm }}</v-col>
      <v-col v-else>0</v-col>
    </v-row>
    <v-row no-gutters class="pa-1">
      <v-col class="font-weight-medium">Properties / Spaces</v-col>
      <v-col v-if="NationalAccount.keystat.property_count">{{ NationalAccount.keystat.property_count }} / {{ NationalAccount.keystat.unitsCount }}</v-col>
      <v-col v-else>0 / 0</v-col>
    </v-row>
  </v-col>
  <v-col>
    <v-row no-gutters class="pa-1">
      <v-col class="font-weight-medium">Discount</v-col>
      <v-col v-if="computedDiscount">{{ computedDiscount }}</v-col>
    </v-row>
    <v-row no-gutters class="pa-1">
      <v-col class="font-weight-medium">Monthly Rent</v-col>
      <v-col v-if="NationalAccount.keystat.monthly_rent">{{ NationalAccount.keystat.monthly_rent | formatMoney }}</v-col>
      <v-col v-else>0.0$</v-col>
    </v-row>
  </v-col>
  <v-col>
    <v-row no-gutters class="pa-1">
      <v-col class="font-weight-medium">Next Bill</v-col>
      <v-col v-if="NationalAccount.keystat.next_bill_date">{{ NationalAccount.keystat.next_bill_date | formatLocalShortDate}}</v-col>
      <v-col v-else>--</v-col>
    </v-row>
  </v-col>
</v-row>
<div v-else-if="contactLeases">
  <v-row no-gutters class="pa-1 px-4">
      <v-col cols="8" class="font-weight-medium">Total SQM</v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-if="NationalAccount.keystat.sqm">{{ NationalAccount.keystat.sqm }}</v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-else>0</v-col>

    </v-row>
    <v-row no-gutters class="pa-1 px-4">
      <v-col cols="8" class="font-weight-medium">Properties / Spaces</v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-if="NationalAccount.keystat.property_count">{{ NationalAccount.keystat.property_count }} / {{ NationalAccount.keystat.unitsCount }}</v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-else>0 / 0</v-col>
    </v-row>
    <v-row no-gutters class="pa-1 px-4">
      <v-col cols="8" class="font-weight-medium">Discount</v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-if="computedDiscount">{{ computedDiscount }}</v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-else></v-col>
    </v-row>
    <v-row no-gutters class="pa-1 px-4">
      <v-col cols="8" class="font-weight-medium">Monthly Rent</v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-if="NationalAccount.keystat.monthly_rent">{{ NationalAccount.keystat.monthly_rent | formatMoney }}</v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-else>0.0$</v-col>
    </v-row>
    <v-row no-gutters class="pa-1 px-4">
      <v-col cols="8"class="font-weight-medium">Next Bill</v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-if="NationalAccount.keystat.next_bill_date">{{ NationalAccount.keystat.next_bill_date }}</v-col>
      <v-col cols="4" class="pa-0 ma-0 d-flex justify-end" v-else>--</v-col>
    </v-row>
</div>

        </template>
<template v-slot:footer>
  <div ref="customAllPropertyfooter">
          <hb-bottom-action-bar cancel-off>
            <template v-slot:right-actions>
              <div class="py-2 d-flex flex-wrap footer-btn-wrapper" ref="childDiv">
              <hb-btn color="secondary" @click="closeAccount" v-if="!condenseActions && contact?.NationalAccount?.status != 0"
                >Close Account</hb-btn
              >
              <hb-btn color="primary" @click="addSpace"  v-if="contact?.NationalAccount?.status != 0">Add Space</hb-btn>
              <hb-btn color="primary" @click="recordPayment">Record a Payment</hb-btn>
              <hb-menu options v-if="condenseActions">
                <v-list>
                  <v-list-item @click="closeAccount">
                    <v-list-item-title>Close Account</v-list-item-title>
                  </v-list-item>
                </v-list>
              </hb-menu>
              </div>
            </template>
</hb-bottom-action-bar>
</div>
</template>
</hb-expansion-panel>
</v-expansion-panels>
<HbEmptyState v-if="contactLeases.length <= 0 && contact?.NationalAccount?.status != 0" class="empty-state-class" header="Click to start renting."
  message="You haven’t added any spaces yet." btn-txt="Add Spaces" @click="addSpace" />

<NationalAccountUnitListView v-else class="mt-5" :class="{'height-350':$vuetify.breakpoint.width > 855,'height-400':$vuetify.breakpoint.width <= 855}" style="flex:1;padding-bottom:10px;" :contact="contact"></NationalAccountUnitListView>

<hb-modal size="medium" title="Close National Account" v-model="iscloseAccount" confirmation>
  <template v-slot:content>
        <div class="py-4 px-6">
            Are you sure you want to close this  national account?
            <br /><br />
            This action cannot be undone.
        </div>
    </template>
  <template v-slot:left-actions>
    </template>
  <template v-slot:right-actions>
        <hb-btn color="destructive" @click="closeAccount1()">Close Account</hb-btn>
    </template>
</hb-modal>
</div>

</template>
<script>
import { notificationMixin } from "@/mixins/notificationMixin.js";
import api from "../../../assets/api.js";
import { EventBus } from '../../../EventBus';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import NationalAccountUnitListView from './NationalAccountUnitListView.vue'


export default {
  name: "NationalAccountOverView",
  props: ["contact", "contactLeases", "propertyView", 'showAside'],
  mixins: [notificationMixin],
  data() {
    return {
      iscloseAccount: false,
      leases: [],
      panel: [0],
      condenseActions: false,
    };
  },
  components: {
    NationalAccountUnitListView
  },
  created() {
      this.emitCondenseFooterActions();
  },
  mounted() {
      this.emitCondenseFooterActions();
  },
  computed: {
    ...mapGetters({
      NationalAccount: 'onBoardingStore/getNationalAccount'
    }),
    computedDiscount() {
      let discountObj = this.NationalAccount?.keystat?.discount
      if (!discountObj) {
        return '--';
      }
      let discount = ""
      switch (discountObj.type) {
        case 'fixed':
          discount = `$${discountObj.value} Fixed`
          break;
        case 'percent':
          discount = `${discountObj.value}% Off`
          break;
        case 'dollar':
          discount = `$${discountObj.value} Off`
      }
      return discount;
    },
  },
  methods: {
    addSpace() {
      EventBus.$emit('HB-Navigation:NationalAccount:Movin',{contact :this.contact, property: this.propertyView });
    },
    recordPayment() {
      EventBus.$emit('HB-Navigation:NationalAccount:RecordPayment', this.contact);
    },
    async closeAccount1() {
      await api.delete(this, api.NATIONAL_ACCOUNT + `${this.$route.query.na_id}?contact_id=${this.$route.params.contact_id}`)
        .then(async (res) => {
          this.iscloseAccount = false
          this.$router.push("/national-accounts");
        })
        .catch((err) => {
          console.log(err);
          this.showMessageNotification({ type: 'error', description: err });
          this.iscloseAccount = false
        })
    },
    async closeAccount() {
      this.iscloseAccount = true

    },
    emitCondenseFooterActions() {
      setTimeout(() => {
      if(this.$refs.customAllPropertyfooter){
          var height = this.$refs.customAllPropertyfooter.clientHeight;
          const childDiv = this.$refs.childDiv;
          console.log('height....', height);
          if(this.$vuetify.breakpoint.width < 600) {
              this.$emit('condense-actions', true);
              this.condenseActions = true;
          } else {
              this.$emit('condense-actions', false);
              this.condenseActions = false;
          }
          
          // if(childDiv.clientHeight > 59 &&  !this.condenseActions){
          //     this.label = false;
          // } else if(!this.condenseActions) {
          //     this.label = true;
          // }
          
      }
    }, 500);
    },
  },
};
</script>
<style lang="css">
.empty-state-class {
  margin-top: 7px;
}
</style>

<style scoped lang="scss">
.height-350 {
  min-height:350px;
}
.height-400 {
  min-height:400px;
}
.overview-wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.footer-btn-wrapper >>> button {
  margin-bottom: 8px!important;
}
@media (max-width: 480px) {
  .overview-wrapper {
    ::v-deep(.hb-bab-right-actions-wrapper) {
      .hb-bab-right-actions-wrapper-span {
        button,
        a {
          margin-left: 6px;
        }
      }
    }
  }
}

</style>
